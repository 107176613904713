<template>
    <div class="conceal">
        <h2>隐私政策</h2>
        <h6>上海五次方非常重视保护您的隐私。</h6>
        为方便您登录、使用相关服务，以及为您提供更个性化的用户体验和服务，您在使用我们的服务时，我们可能会收集和使用您的相关信息。我们希望通过本隐私政策向您说明，在使用上海五次方电脑端、移动端及后续更新版本（以下统称“本平台”）时，我们如何收集、使用、储存和披露您的信息，以及我们为您提供的访问、更新、控制和保护这些信息的方式。本隐私政策与您所使用的上海五次方相关，希望您仔细阅读。
        您使用我们的服务，即意味着您已经同意我们按照本隐私政策收集、使用、储存和披露您的相关信息，以及向您提供的控制和保护措施。
        <h4>一、适用范围</h4>
        <p>1.1本隐私政策适用于本平台所有服务。服务包括向您提供页面浏览、网站登录服务，以及通过本平台向您提供的技术服务。</p>
        <p>1.2需要特别说明的是，作为本平台的用户，若您利用本平台的技术服务，为您的用户再行提供服务，因您的业务数据属于您所有，您应当另行与您的用户约定隐私政策。
        </p>
        <p>1.3本隐私政策中的“信息”特指第二条所收集的信息，本隐私政策不适用于您存储在本平台系统中的数据。您可通过《四川省政务服务网用户服务协议》知悉更多关于您存储在本平台中数据的相关政策。
        </p>
        <h4>二、信息收集范围</h4>
        <p> 2.1 为完成本平台账户的注册、管理、实名认证等必要活动，您需要提交真实、合法、有效的信息，包括但不限于您的姓名、身份证号码、联系电话、人脸生物信息、银行账户信息、工商登记信息等。
        </p>
        <p> 2.2 您使用本服务时，可能收集已经经过您授权获取或主动填写的如下信息：
        </p>
        <p> 2.2.1位置信息，指您开启设备定位功能并使用我们基于位置提供的相关服务时，收集的有关您位置的信息，包括：
            • 您通过具有定位功能的移动设备使用我们的服务时，通过GPS或WiFi等方式收集的您的地理位置信息。
            • 您或其他用户提供的包含您所处地理位置的实时信息，例如您提供的账户信息中包含的您所在地区信息，您上传的显示您当前或曾经所处地理位置的共享信息，您或其他人共享的照片包含的地理标记信息。
            • 您可以通过关闭定位功能，停止对您的地理位置信息的收集。
        </p>
        <p> 2.3 如果您需要使用部分可选的功能或服务，如，公积金、社保、公共交通、税务、户政、公共服务等，您还应该提供使用该功能或服务所必须的信息，如联系人、联系方式、身份证、照片等。
        </p>
        <p> 2.4 如果您需要在线上申请线下交付或进行产品测试，为履行所必需，您应提供联系人、联系方式、地址等必要信息。
        </p>
        <h4>三、如何收集信息</h4>
        <p> 3.1 我们通过您主动提交，以及在您使用我们的服务过程中产生相应的记录等方式收集您的信息。
        </p>
        <p> 3.2 我们可能会通过 Cookies 和其他相关技术收集和使用您的信息。我们使用 Cookies 的具体用途包括：
            • 记住您的身份。例如：Cookies 有助于我们辨认您作为我们的注册用户的身份。
            • 分析您使用我们服务的情况，以便为您提供更加周到的个性化服务，包括定制化页面、推荐等服务。
        </p>
        <p> 3.3 您可以通过浏览器设置拒绝或管理 Cookies。但请注意，如果停用 Cookies，您有可能无法享受最佳的服务体验，某些功能的可用性可能会受到影响。
        </p>
        <p> 3.4 通过本平台 Cookies 记录的有关信息，将适用本隐私政策。
        </p>
        <h4>四、信息使用</h4>
        <p>为了向您提供更加优质、便捷、安全的服务，在符合相关法律法规的前提下，我们可能将收集的信息用作以下用途：
        </p>
        <p>• 向您提供服务（如实名认证信息、用来接受验证码的手机号，是继续获得服务的前提）；</p>
        <p>• 满足您的个性化需求。例如，语言设定、位置设定、个性化的帮助服务和指示，或对您和其他用户作出其他方面的回应。</p>
        <p>• 服务优化和开发。例如，我们会根据本平台系统响应您的需求时产生的信息，优化我们的服务。</p>
        <p>•保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或本平台相关协议、规则的情况，我们可能使用您的帐号信息、并整合设备信息、有关网络日志以及我们关联公司、合作伙伴分享的信息，来判断您账户风险、进行身份验证、安全事件的检测及防范，并依法采取必要的记录、审计、分析、处置措施。例如，我们会将您的信息用于身份验证、安全防范、投诉处理、纠纷协调、诈骗监测等用途。您在使用安全功能或其他类似服务时，我们会对恶意程序或病毒进行检测，或为您识别诈骗信息。
        </p>
        <p>• 向您提供与您更加相关的服务。例如，向您提供您可能感兴趣的类似功能或服务等。</p>
        <p>• 邀请您参与有关我们产品和服务的调查。</p>
        <p>• 其他可能需要使用收集的信息的相关场景，如果使用场景与初始场景无合理的关联性，我们会在使用信息前重新征得您的同意。
        </p>
        <h4>五、信息共享、转让、披露</h4>
        <p> 5.1 共享</p>
        <p> 我们不会与其他组织和个人共享您的用户信息，但以下情况除外：</p>
        <p> 5.1.1在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的用户信息；</p>
        <p> 5.1.2在法定情形下的共享：我们可能会根据法律法规规定、诉讼、仲裁解决需要，或按行政、司法机关依法提出的要求，对外共享您的用户信息；
        </p>
        <p> 5.1.3为了促成办理服务或协助解决争议，某些情况下只有共享您的用户信息，才能促成办理或处理您与他人的纠纷或争议。
        </p>
        <p> 5.1.4与授权合作伙伴共享：我们可能委托受信赖的合作伙伴来提供服务（如人脸识别服务），因此我们可能会与合作伙伴共享您的某些用户信息，以提供更好的客户服务和优化用户体验。我们仅会出于合法、正当、必要、特定、明确的目的共享您的用户信息，并且只会共享提供服务所必要的用户信息。我们的合作伙伴无权将共享的用户信息用于任何其他用途。
            目前，我们的授权合作伙伴包括如下类型：供应商、服务提供商和其他合作伙伴。我们将信息发送给支持我们业务的供应商、服务提供商和其他合作伙伴，这些支持包括提供基础技术服务、提供咨询、分析等专业服务。
            对我们与之共享用户信息的公司、组织和个人，我们会与其签署严格的保密协议以及信息保护约定，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理用户信息。
        </p>
        <p>5.2 转让</p>
        <p> 我们不会将您的用户信息转让给任何公司、组织和个人，但以下情况除外：</p>
        <p>5.2.1在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的用户信息。</p>
        <p> 5.3 披露</p>
        <p> 未经您同意，我们不会与任何无关第三方分享您的信息，以下情形除外：</p>
        <p> 5.3.1我们可能将您的信息与我们的关联公司、第三方服务提供商、承包商及代理分享，仅用作下列用途：</p>
        <p> • 提供“二、信息收集范围”中的相应功能或服务所必需，以及出于“四、信息使用”中的部分所述目的所必需。</p>
        <p>• 履行我们在《四川省政务服务网用户服务协议》或本隐私政策中的义务和行使我们的权利。</p>
        <p> 如我们与任何上述第三方分享您的信息，我们将努力确保第三方在使用您的信息时遵守本声明及我们要求其遵守的其他适当的保密和安全措施。
        </p>
        <p> 5.3.2随着我们业务的持续发展，我们以及我们的关联公司有可能进行合并、收购、资产转让或类似的交易，您的信息有可能作为此类交易的一部分而被转移。我们将遵守相关法律法规的要求，在转移前通知您，确保信息在转移时的机密性，以及变更后继续履行相应责任和义务。
        </p>
        <p> 5.3.3我们还可能因以下原因而披露您的信息：</p>
        <p> • 遵守适用的法律法规等有关规定。</p>
        <p> • 遵守法院判决、裁定或其他法律程序的规定。</p>
        <p>• 遵守相关政府机关或其他有权机关的要求。</p>
        <p> • 我们有理由确信需遵守法律法规等有关规定。</p>
        <p> • 为执行相关服务协议或本隐私政策、维护社会公共利益、处理投诉/纠纷，保护我们的客户、我们或我们的关联公司、其他用户或雇员的人身和财产安全或合法权益所合理必需的用途。
        </p>
        <p> • 经过您合法授权的情形。如我们因上述原因而披露您的信息，我们将在遵守法律法规相关规定及本政策的基础上及时告知您。
        </p>
        <h4>六、信息存储</h4>
        <p>6.1 本平台收集的有关您的信息保存在本平台位于中国大陆的服务器。</p>
        <p>6.2 通常，我们仅在为您提供服务期间保留您的信息，保留时间不会超过满足相关使用目的所必须的时间。 但在下列情况下，且仅出于下列情况相关的目的，我们有可能需要较长时间保留您的信息或部分信息：
        </p>
        <p> • 遵守适用的法律法规等有关规定。</p>
        <p>• 遵守法院判决、裁定或其他法律程序的要求。</p>
        <p>• 遵守相关政府机关或其他有权机关的要求。</p>
        <p>• 我们有理由确信需遵守法律法规等有关规定。</p>
        <p> • 为执行相关服务协议或本隐私政策、维护社会公共利益、处理投诉/纠纷，保护我们的客户、我们或我们的关联公司、其他用户或雇员的人身和财产安全或合法权益所合理必需的用途。
        </p>
        <h4>七、信息安全</h4>
        <p> 7.1 如果您拥有本平台其他帐号，在特定场景下，您的本平台帐号的个人信息可能会与您在本平台拥有的其他账户信息相关联。例如，您使用微信支付注册本平台帐号。
        </p>
        <p> 7.2 我们努力保障信息安全，以防信息的丢失、不当使用、未经授权阅览或披露。
        </p>
        <p> • 我们使用各种安全技术以保障信息的安全。例如，我们将通过服务器多备份、密码加密等安全措施，防止信息泄露、毁损、丢失。
        </p>
        <p> • 我们建立严格的管理制度和流程以保障信息的安全。例如，我们严格限制访问信息的人员范围，并进行审计，要求他们遵守保密义务。
        </p>
        <p>• 我们重视信息安全合规工作，并通过众多国际和国内的安全认证，如网络安全等级保护认证等，以业界先进的解决方案充分保障您的信息安全。
            但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网环境下，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全。您需要了解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。
            若发生个人信息泄露等安全事件，我们会启动应急预案，阻止安全事件扩大，按照《国家网络安全事件应急预案》等有关规定及时上报，并以发送邮件、推送通知、公告等形式告知您相关情况，并向您给出安全建议。
        </p>
        <p> 7.3
            为更有效的保障您的信息安全，我们也希望您能够加强自我保护意识。我们仅在本平台直接导致您个人信息泄露的范围内承担直接经济责任，因此，请您妥善保管您的帐号及密码信息，避免您的个人信息泄露。本平台账户及微信帐号均有安全保护功能，但您也需要妥善保护自己的个人信息，除非您判断认为必要的情形下，不向任何第三人提供您的帐号密码等个人信息。
        </p>
        <h4>八、访问与控制</h4>
        <p> 8.1 您可以在使用我们服务的过程中，访问、修改和删除您的相关信息。您访问、修改和删除信息的方式将取决于您使用的具体服务。
        </p>
        <p>8.2 如您发现我们违反法律法规的规定或者双方的约定收集、使用您的信息，您可以要求我们删除。如您发现我们收集、存储的您的信息有错误的，且无法自行更正的，您也可以要求我们更正。
        </p>
        <p> 8.3 在访问、修改和删除相关信息时，我们可能会要求您进行身份验证，以保障账户安全。请您理解，由于技术所限、基于法律法规要求，您的某些请求可能无法进行响应。
        </p>
        <p> 8.4 基于法律法规要求、保障信息安全等正当事由，您的部分信息可能无法访问、修改和删除。
        </p>
        <p> 8.5 您可以自行选择撤回对某些非基本功能或服务对您信息处理的同意，并通过联系客服的方式选择注销本平台帐号；
        </p>
        <p> 8.6 如您对上述权利实现存在疑问，可以根据“十、与我们联系”中的相关联系方式与我们取得联系
        </p>
        <h4>九、隐私政策的更新</h4>
        我们可能适时修订本隐私政策的条款，该等修订构成本《隐私政策》的一部分。如可能造成您在本隐私政策下权利的实质减少或扩大收集、使用信息的范围等重要规则变更时，我们将在修订生效前通过在主页上显著位置提示或向您发送电子邮件或以其他方式通知您。在该种情况下，若您继续使用我们的服务，即表示同意受经修订的本隐私政策的约束。
        <h4>十、与我们联系</h4>
        如您对本政策或其他相关事宜有疑问，您可以通过12345进行咨询或根据我们提供的指引，填写意见反馈。我们将尽快审核所涉问题，并在验证您的用户身份后予以回复。
    </div>
</template>

<script>

export default {
    name: 'AboutUser',
    setup() {

        return {

        }
    }
}
</script>
<style scoped>
.conceal {
    margin: 0 20%;
}
</style>