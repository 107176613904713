<template>
  <div>
    <!--header-->
    <header id="site-header" class="fixed-top">
      <div class="container">
        <nav class="navbar navbar-expand-lg navbar-dark stroke">
          <h1>
            <a class="navbar-brand" href="#">
              <img src="../assets/logo.png" alt="Your logo" title="logo" style="height:50px;" />
              <span class="fa fa-align-right"></span> 5th Paradigm
              <span class="logo" style="margin-left: 14px;">上海五次方智能科技有限公司</span></a>
          </h1>

          <!-- if logo is image enable this   -->
          <!--            <a class="navbar-brand" href="#">-->
          <!--              <img src="../assets/logo.png" alt="Your logo" title="Your logo" style="height:35px;" />-->
          <!--            </a>-->

          <button class="navbar-toggler  collapsed bg-gradient" type="button" data-toggle="collapse"
            data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false"
            aria-label="Toggle navigation">
            <span class="navbar-toggler-icon fa icon-expand fa-bars"></span>
            <span class="navbar-toggler-icon fa icon-close fa-times"></span>
          </button>

          <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
            <ul class="navbar-nav mx-lg-auto">
              <li class="nav-item active">
                <a class="nav-link" href="#">首页 <span class="sr-only">(current)</span></a>
              </li>
              <li class="nav-item @@about__active">
                <a class="nav-link" href="#">服务与产品</a>
              </li>
              <li class="nav-item @@services__active">
                <a class="nav-link" href="#">合作伙伴</a>
              </li>
              <li class="nav-item @@contact__active">
                <a class="nav-link" href="#">新闻</a>
              </li>
            </ul>
          </div>

          <!--/search-right-->
          <div class="header-search">
            <div class='control mr-3'>
              <div class='btn-material'>
                <i class='fa fa-search icon-material-search'></i>
              </div>
            </div>
            <!-- full screen form controls -->
            <i class='icon-close fa fa-close material-icons'></i>
            <form action="#" method="GET" class='search-input'>
              <input class='input-search' placeholder='Start Typing' type='text'>
            </form>
          </div>
          <!--//search-right-->

          <!-- toggle switch for light and dark theme -->
          <div class="mobile-position">
            <nav class="navigation">
              <div class="theme-switch-wrapper">
                <label class="theme-switch" for="checkbox">
                  <input type="checkbox" id="checkbox">
                  <!--                  <div class="mode-container py-1">-->
                  <!--                    <i class="gg-sun"></i>-->
                  <!--                    <i class="gg-moon"></i>-->
                  <!--                  </div>-->
                </label>
              </div>
            </nav>
          </div>
          <!-- //toggle switch for light and dark theme -->
        </nav>

      </div>
    </header>
    <!-- slider -->
    <div class="banner-wrap">
      <div class="banner-slider">
        <!-- hero slide start -->
        <div class="banner-slide bg1">
          <div class="container">
            <div class="hero-content">
              <h1 data-animation="flipInX" data-delay="0.8s" data-color="#fff">
                AI"赋能"传统行业</h1>
              <p data-animation="fadeInDown" class="mt-4">
                深耕于人工智能领域，积累丰富的机器学习、深度学习和大数据开发项目经验。了解传统各行业对AI算法的需求，推出智能化、规范化的AI产品服务，为传统行业提供AI“赋能”
              </p>
              <div class="cta-btn" data-animation="fadeInUp" data-delay="1s">
                <a href="#url" class="btn btn-style btn-primary">了解更多</a>
              </div>
            </div>
          </div>
          <div class="hero-overlay"></div>
        </div>
        <!-- hero slide end -->
        <!-- hero slide start -->
        <div class="banner-slide bg2">
          <div class="container">
            <div class="hero-content">
              <h1 data-animation="fadeInDown" data-delay="0.8s" data-color="#fff">
                通用 OCR 识别
              </h1>
              <p data-animation="fadeInDown" class="mt-4">
                针对多场景、多业务的通用ocr识别，基于深度学习技术，支持图片文字识别、pdf内容识别、发票内容识别等常见需求，还可返回文字的位置信息、字符内容等</p>
              <div class="cta-btn" data-animation="fadeInDown" data-delay="1s">
                <a href="#url" class="btn btn-style btn-primary">了解更多</a>
              </div>
            </div>
          </div>
          <div class="hero-overlay"></div>
        </div>

        <div class="banner-slide bg3">
          <div class="container">
            <div class="hero-content">
              <h1 data-animation="fadeInUp" data-color="#fff" data-delay="0.8s">AI"赋能"智能制造</h1>
              <p data-animation="fadeInUp" class="mt-4">
                服务于制造业的智能化，AI新技术让基于机器视觉的工业缺陷检测以及基于时序数据的智能预测成为传统制造企业实施智能转型的左膀右臂，模块化的端-边-云协同的AI应用模式被越来越多的企业认同和采用 </p>
              <div class="cta-btn" data-animation="fadeInDown" data-delay="1s">
                <a href="#" class="btn btn-style btn-primary">了解更多</a>
              </div>
            </div>
          </div>
          <div class="hero-overlay"></div>
        </div>
      </div>
      <div class="shape">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 280">
          <path fill-opacity="1">
            <animate attributeName="d" dur="20000ms" repeatCount="indefinite"
              values="M0,160L48,181.3C96,203,192,245,288,261.3C384,277,480,267,576,234.7C672,203,768,149,864,117.3C960,85,1056,75,1152,90.7C1248,107,1344,149,1392,170.7L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z; M0,160L48,181.3C96,203,192,245,288,234.7C384,224,480,160,576,133.3C672,107,768,117,864,138.7C960,160,1056,192,1152,197.3C1248,203,1344,181,1392,170.7L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z;												 M0,64L48,74.7C96,85,192,107,288,133.3C384,160,480,192,576,170.7C672,149,768,75,864,80C960,85,1056,171,1152,181.3C1248,192,1344,128,1392,96L1440,64L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z;
                                                                 M0,160L48,181.3C96,203,192,245,288,261.3C384,277,480,267,576,234.7C672,203,768,149,864,117.3C960,85,1056,75,1152,90.7C1248,107,1344,149,1392,170.7L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z;" />

          </path>

        </svg>
      </div>
    </div>
    <!-- services section -->
    <div class="w3l-servicesblock2" id="services">
      <section id="grids5-block" class="py-5">
        <div class="container py-lg-5 py-md-4 py-2">
          <h3 class="title-big text-center">了解我们提供的服务</h3>
          <div class="row mt-lg-5 mt-4 text-center">
            <div class="col-lg-4 col-md-6">
              <div class="grids5-info">
                <a href="#service" class="d-block zoom"><img src="../assets/images/industry.png" alt=""
                    class="img-fluid service-image" /></a>
                <div class="blog-info">
                  <a href="#service" class="title">工业质检</a>
                  <p class="text-para">
                    5"A"质检云平台、整车质检
                  </p>
                </div>
                <a href="#learnmore" class="btn btn-style1 mt-3">了解更多</a>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mt-md-0 mt-5">
              <div class="grids5-info">
                <a href="#service" class="d-block zoom"><img src="../assets/images/service2.jpg" alt=""
                    class="img-fluid service-image" /></a>
                <div class="blog-info">
                  <a href="#service" class="title">算法开发/优化</a>
                  <p class="text-para">
                    知识图谱、NER、事件关系抽取、OCR识别、工业4.0、智能工厂、算法解决方案<br>
                    <!-- 模型优化、模型调整、模型改造 -->
                  </p>
                </div>
                <a href="#learnmore" class="btn btn-style1 mt-3">了解更多</a>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 mt-lg-0 mt-5">
              <div class="grids5-info">
                <a href="#service" class="d-block zoom"><img src="../assets/images/service3.jpg" alt=""
                    class="img-fluid service-image" /></a>
                <div class="blog-info">
                  <a href="#service" class="title">智能制造</a>
                  <p class="text-para" style="font-size: 14px;">
                    基于深度学习算法的强大视觉缺陷检测能力和方案；
                    基于时间序列数据的维护性预测、告警等自动化建模能力和方案
                  </p>
                </div>
                <a href="#learnmore" class="btn btn-style1 mt-3">了解更多</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <!-- projects -->
    <section class="w3l-gallery py-5" id="gallery">
      <div class="container py-lg-5 py-md-4 py-2">
        <div class="title-content text-center mb-5">
          <h3 class="title-small"> Our Success Projects</h3>
          <h3 class="title-big mx-lg-5">合作项目</h3>
        </div>
        <div class="row">
          <!-- <div class="col-lg-4 col-md-6 item">
            <a href="https://www.performercn.com/home/page" target="_blank" data-lightbox="example-set"
              data-title="Project 1" class="zoom d-block">
              <img class="card-img-bottom d-block" src="../assets/images/solidity.jpg" alt="Card image cap">
              <span class="overlay__hover"></span>
              <span class="hover-content">
                <span class="title">维信X-ray数据检测</span>
                <span class="content">
                  对维信电子厂电路板质检环节的表面锡桥、少锡、缺件外观质量的问题，应用自动化和视觉识别方式，实现自动外观质量检测
                </span>
              </span>
            </a>
          </div> -->
          <div class="col-lg-4 col-md-6 item mt-lg-0 mt-4">
            <a href="#" data-lightbox="example-set" data-title="Project 2" class="zoom d-block">
              <img class="card-img-bottom d-block" src="../assets/img/5a.jpg" alt="Card image cap">
              <!-- <img class="card-img-bottom d-block" src="../assets/img/Vision.jpg" alt="Card image cap"> -->
              <span class="overlay__hover"></span>
              <span class="hover-content">
                <span class="title">5“A”Vision训推一体工业云平台</span>
                <span class="content">
                  创造性打造”端，边，云“的闭环生态支撑业内高效协作，整体架构具有高精度，强解耦，零代码，跨行业，落地快等特点
                </span>
              </span>
            </a>
          </div>

          <div class="col-lg-4 col-md-6 item mt-lg-0 mt-4">
            <a href="#" data-lightbox="example-set" data-title="Project 3" class="zoom d-block">
              <img class="card-img-bottom d-block" src="../assets/images/cloth.jpg" alt="Card image cap">
              <span class="overlay__hover"></span>
              <span class="hover-content">
                <span class="title">三元布匹缺陷检测</span>
                <span class="content">对三元纺织厂布匹质检环节的表面黑点、跑线等外观质量问题，应用自动化和视觉识别方式，实现自动外观质量检测</span>
              </span>
            </a>
          </div>

          <!-- <div class="col-lg-4 col-md-6 item mt-4  pt-lg-2"> -->
              <div class="col-lg-4 col-md-6 item mt-lg-0 mt-4">
            <a href="#" data-lightbox="example-set" data-title="Project 4" class="zoom d-block">
              <img class="card-img-bottom d-block" src="../assets/images/bottle.jpg" alt="Card image cap">
              <span class="overlay__hover"></span>
              <span class="hover-content">
                <span class="title">包装机械行业</span>
                <span class="content">红外涂胶检测系统<br />红外液位检测系统<br />吹瓶机检测系统<br />瓶盖视觉检测系统
                  <br />标签视觉检测系统<br />...</span>
              </span>
            </a>
          </div>

          <div class="col-lg-4 col-md-6 item mt-4  pt-lg-2">
            <a href="#" data-lightbox="example-set" data-title="Project 4" class="zoom d-block">
              <img class="card-img-bottom d-block" src="../assets/images/photovoltaic.jpg" alt="Card image cap">
              <span class="overlay__hover"></span>
              <span class="hover-content">
                <span class="title">光伏行业</span>
                <span class="content">硅片外观缺陷检测<br />电池片外观检测<br />电池片 EL 检测<br />绝缘片定位及位置度检测<br />...</span>
              </span>
            </a>
          </div>

          <!--  href="./TKK.vue" -->
          <!-- <div class="col-lg-4 col-md-6 item mt-4 pt-lg-2" @click="TKK = true">
            <a data-lightbox="example-set" data-title="Project 5" class="zoom d-block">
              <img class="card-img-bottom d-block" src="../assets/images/c51.jpg" alt="Card image cap">
              <span class="overlay__hover"></span>
              <span class="hover-content">
                <span class="title">体康康配置平台</span>
                <span class="content">在线预约陪诊陪护服务平台</span>
              </span>
            </a>
          </div>         -->

          <div class="col-lg-4 col-md-6 item mt-4 pt-lg-2">
            <a href="#" data-lightbox="example-set" data-title="Project 5" class="zoom d-block">
              <img class="card-img-bottom d-block" src="../assets/images/car.jpg" alt="Card image cap">
              <span class="overlay__hover"></span>
              <span class="hover-content">
                <span class="title">汽车行业</span>
                <span class="content">冲压件质量检测
                  <br />焊接质量检测
                  <br />汽车组件识别与装配
                  <br />锂电隔膜涂布表面缺陷检测
                  <br />安全带表面缺陷检测
                  <br />...
                </span>
              </span>
            </a>
          </div>
          <!-- <div v-if="TKK" class="tkk-box">
            <div class="nav-top">
              <div>
                <img src="../assets/img/head.jpg" alt="">
              </div>
              <div class="top-text">
                <div style="display: flex;justify-content: space-between;">
                  <div style="font-size: 20px; font-weight: 800;">体康康</div>
                  <div><button @click="TKK = false">关闭</button></div>
                </div>

                <p>
                  这是一个在线陪护项目，功能有：现场扫码下单陪护，下单选择要陪护的内容，分为住院陪护和门诊陪护，住院陪护需要先预支付在进行陪护，门诊下单完成即可进行陪护。在线预约下单，是附近有陪护人员即可接单进行陪护，下单之后显示用户和工作人员的位置，可以实时看到所在位置，其他同现场扫码下单一样。下单可以添加多个人员，方便下单时进行选择。还可以查看订单，查看全部的订单，已完成的订单，已取消的订单，进行中的订单，已接单的订单，订单完成需要支付的订单。工作人员的注册以及接单。
                </p>
              </div>
            </div>
            <div class="nav-bottom">
              <img src="../assets/img/peihu.jpg" alt="">
              <img src="../assets/img/add-p.jpg" alt="">
              <img src="../assets/img/add.jpg" alt="">
              <img src="../assets/img/index.jpg" alt="">
              <img src="../assets/img/home.jpg" alt="">
            </div>
          </div> -->

          <div class="col-lg-4 col-md-6 item mt-4 pt-lg-2">
            <a href="#" data-lightbox="example-set" data-title="Project 6" class="zoom d-block">
              <img class="card-img-bottom d-block" src="../assets/images/3c.jpg" alt="Card image cap">
              <span class="overlay__hover"></span>
              <span class="hover-content">
                <span class="title">3C电子行业</span>
                <span class="content">焊接质量检测<br />喇叭网外观缺陷检测<br />点胶定位、溢胶检测<br />外壳、屏幕表面缺陷检测<br />...</span>
              </span>
            </a>
          </div>

          <div class="col-lg-4 col-md-6 item mt-4 pt-lg-2">
            <a href="#" data-lightbox="example-set" data-title="Project 6" class="zoom d-block">
              <img class="card-img-bottom d-block" src="../assets/img/luntiao.png" alt="Card image cap">
              <span class="overlay__hover"></span>
              <span class="hover-content">
                <span class="title">常熟涤纶线饼识别</span>
                <span class="content">对常熟涤纶厂纱锭质检环节的表面波纹、跳丝等外观质量问题，应用自动化和视觉识别方式，实现自动外观质量检测；实现配置项检测漏检率&lt;0.5%</span>
              </span>
            </a>
          </div>
          <div class="col-lg-4 col-md-6 item mt-4 pt-lg-2">
            <a href="#" data-lightbox="example-set" data-title="Project 6" class="zoom d-block">
              <img class="card-img-bottom d-block" src="../assets/img/liuqi.png" alt="Card image cap">
              <span class="overlay__hover"></span>
              <span class="hover-content">
                <span class="title">整车质检子平台</span>
                <span class="content">应用视觉识别，配置项检测漏检率&lt;1%，检测准确率:>95%；缺陷识别精度:≤1mm提升检测效率，便于客户分析制程，提升客户装配良率，降本增效</span>
              </span>
            </a>
          </div>
          <!-- <div class="text-center w-100 mt-md-5 mt-4">
              <a href="#loadmore" class="load btn btn-style btn-outline-primary"> Load More </a>
          </div> -->
        </div>
        <!-- Company logos -->
        <!--        <div class="row company-logos pt-5 mt-5 justify-content-center">-->
        <!--          <div class="col-lg-2 col-md-3 col-4">-->
        <!--            <img src="../assets/images/brand1.png" alt="" class="img-fluid">-->
        <!--          </div>-->
        <!--          <div class="col-lg-2 col-md-3 col-4">-->
        <!--            <img src="../assets/images/brand6.png" alt="" class="img-fluid">-->
        <!--          </div>-->
        <!--          <div class="col-lg-2 col-md-3 col-4">-->
        <!--            <img src="../assets/images/brand3.png" alt="" class="img-fluid">-->
        <!--          </div>-->
        <!--          <div class="col-lg-2 col-md-3 col-4 mt-md-0 mt-4">-->
        <!--            <img src="../assets/images/brand4.png" alt="" class="img-fluid">-->
        <!--          </div>-->
        <!--          <div class="col-lg-2 col-md-3 col-4 mt-lg-0 mt-4">-->
        <!--            <img src="../assets/images/brand5.png" alt="" class="img-fluid">-->
        <!--          </div>-->
        <!--        </div>-->
        <!-- //Company logos -->
      </div>
    </section>
    <!-- company values -->
    <section class="w3l-features py-5" id="work">
      <div class="container py-lg-5 py-md-4 py-2">
        <div class="row main-cont-wthree-2 align-items-center">
          <div class="col-lg-6 feature-grid-left pr-lg-5">
            <h5 class="title-small">Every day brings new challenges</h5>
            <h3 class="title-big mb-4">公司价值观与发展目标</h3>
            <p class="text-para">
            </p>
            <a href="#" class="btn btn-style btn-primary mt-lg-5 mt-4">More</a>
          </div>
          <div class="col-lg-6 feature-grid-right mt-lg-0 mt-md-5 mt-4">
            <div class="call-grids-w3 d-grid">
              <div class="grids-1 box-wrap">
                <div class="icon">
                  <img src="../assets/images/value1.png" alt="" class="img-fluid">
                </div>
                <h4><a class="title-head">真诚</a></h4>
              </div>
              <div class="grids-1 box-wrap">
                <div class="icon">
                  <img src="../assets/images/value2.png" alt="" class="img-fluid">
                </div>
                <h4><a href="#" class="title-head">利他</a></h4>
              </div>
              <div class="grids-1 box-wrap">
                <div class="icon">
                  <img src="../assets/images/value3.png" alt="" class="img-fluid">
                </div>
                <h4><a href="#" class="title-head">精进</a></h4>
              </div>
              <div class="grids-1 box-wrap">
                <div class="icon">
                  <img src="../assets/images/value4.png" alt="" class="img-fluid">
                </div>
                <h4><a href="#" class="title-head">共赢</a></h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- footer -->
    <footer class="w3l-footer-29-main">
      <div class="footer-29 py-5">
        <div class="container py-md-4">

          <div class="row footer-top-29">

            <div class="col-lg-4 col-md-6 footer-list-29 footer-1">
              <div class="footer-logo mb-4">
                <a class="navbar-brand" href="#">
                  <span class=" fa-align-right">5th Paradigm</span>
                  <span class="logo">上海五次方智能科技有限公司</span></a>
              </div>
              <p>
                上海五次方智能科技有限公司是一家以人工智能技术为核心的科技公司，致力于为工业、制造业企业提供一站式视觉算法解决方案，现已为包括东风柳汽、常熟涤纶在内的3C，整车，白色家电，纺织等大型企业提供落地智能质检、巡检以及工厂/工地智慧安防等工业平台。为企业降本增效，帮助传统工业&制造业向数字化、智能化转型，助力企业高质量发展。
              </p>
              <!--              <div class="main-social-footer-29 mt-md-4 mt-3">-->
              <!--                <a href="#facebook" class="facebook"><span class="fa fa-facebook"></span></a>-->
              <!--                <a href="#twitter" class="twitter"><span class="fa fa-twitter"></span></a>-->
              <!--                <a href="#instagram" class="instagram"><span class="fa fa-instagram"></span></a>-->
              <!--                <a href="#linkedin" class="linkedin"><span class="fa fa-linkedin"></span></a>-->
              <!--              </div>-->
            </div>

            <div class="col-lg-4 col-md-6 footer-list-29 footer-1 pr-lg-5 mt-md-0 mt-5">
              <h6 class="footer-title-29">联系我们 </h6>
              <p class="mb-2">公司地址 : 上海市奉贤区金海公路6055号29幢501</p>
              <p class="mb-2">联系电话 : 18071623868 / 19901767068</p>
              <p class="mb-2">电子邮箱 : fanlinqi@5paradigm.com</p>
            </div>


            <div class="col-lg-4 col-md-6 col-sm-5 col-6 footer-list-29 footer-2 mt-lg-0 mt-5">
              <ul>
                <h6 class="footer-title-29">业务服务</h6>
                <li><a href="#">知识图谱/NER</a></li>
                <li><a href="#">OCR识别</a></li>
                <li><a href="#">工业4.0/智能工厂</a></li>
                <!-- <li><a href="#">算法解决方案设计</a></li> -->
                <!-- <li><a href="#">模型优化/模型调整/模型改造</a></li> -->
                <li><a href="#">5“A”Vision训推一体工业云平台产品</a></li>
                <li><a href="#">目标检测，图像分割，肢体行为，物体分类</a></li>
                <li><a href="#">工业视觉（质检，巡检，工厂安防）</a></li>
                <!-- <li><a href="#">Web网站开发/系统开发</a></li>
                <li><a href="#">微信小程序开发</a></li> -->
              </ul>
            </div>
            <div class="col-lg-4 col-md-6 footer-list-29 footer-1 pr-lg-5 mt-md-0 mt-5">
              <h6 class="footer-title-29" @click="$router.push('/aboutUser')">用户协议 </h6>
            </div>


            <!--            <div class="col-lg-2 col-md-6 col-sm-5 col-6 footer-list-29 footer-2 mt-lg-0 mt-5">-->
            <!--              <ul>-->
            <!--                <h6 class="footer-title-29">Company</h6>-->
            <!--                <li><a href="#"> About company</a></li>-->
            <!--                <li><a href="#">Latest Blog posts</a></li>-->
            <!--                <li><a href="#">Our Gallery </a></li>-->
            <!--                <li><a href="#">Vision to future</a></li>-->
            <!--                <li><a href="#">Get in touch</a></li>-->
            <!--              </ul>-->
            <!--            </div>-->

          </div>

        </div>
      </div>
      <!-- copyright -->
      <section class="w3l-copyright text-center">
        <div class="container">
          <p class="copy-footer-29">Copyright &copy; 2021.上海五次方智能科技有限公司版权所有
            <!--            <a target="_blank" href="https://sc.chinaz.com/moban/">&#x7F51;&#x9875;&#x6A21;&#x677F;</a>-->
          </p>
          <p>网站备案号 沪ICP备2021015420号-1</p>
        </div>
        <!-- move top -->
        <button @click="topFunction()" id="movetop" title="回到顶部">
          &#10548;
        </button>
      </section>
    </footer>
  </div>
</template>

<script>
import $ from 'jquery';
import initSlick from "../assets/js/slickEs6.js"

// When the user scrolls down 20px from the top of the document, show the button
window.onscroll = function () {
  scrollFunction()
};

function scrollFunction() {
  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    document.getElementById("movetop").style.display = "block";
  } else {
    document.getElementById("movetop").style.display = "none";
  }
}

$(window).on("scroll", function () {
  var scroll = $(window).scrollTop();

  if (scroll >= 80) {
    $("#site-header").addClass("nav-fixed");
  } else {
    $("#site-header").removeClass("nav-fixed");
  }
});

function slideAnimate(elements) {
  var animationEndEvents = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
  elements.each(function () {
    var $this = $(this);
    var $animationDelay = $this.data('delay');
    var $animationType = 'animated ' + $this.data('animation');
    $this.css({
      'animation-delay': $animationDelay,
      '-webkit-animation-delay': $animationDelay
    });
    $this.addClass($animationType).one(animationEndEvents, function () {
      $this.removeClass($animationType);
    });
  });
}

export default {
  data() {
    return {
      TKK: false,
      // aaa:this.goToUser()
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      initSlick($);
      let bannerSlider = $('.banner-slider');
      let bannerFirstSlide = $('div.banner-slide:first-child');

      bannerSlider.on('init', function (e, slick) {
        let firstAnimatingElements = bannerFirstSlide.find('[data-animation]');
        slideAnimate(firstAnimatingElements);
      });
      bannerSlider.on('beforeChange', function (e, slick, currentSlide, nextSlide) {
        let animatingElements = $('div.slick-slide[data-slick-index="' + nextSlide + '"]').find('[data-animation]');
        slideAnimate(animatingElements);
      });
      bannerSlider.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        fade: true,
        dots: false,
        swipe: true,
        adaptiveHeight: true,
        responsive: [
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              autoplay: false,
              autoplaySpeed: 4000,
              swipe: true,
            }
          }
        ]
      });
      // data color
      $("[data-color]").each(function () {
        $(this).css('color', $(this).attr('data-color'));
      });
      // data background color
      $("[data-bgcolor]").each(function () {
        $(this).css('background-color', $(this).attr('data-bgcolor'));
      })
    },
    // When the user clicks on the button, scroll to the top of the document
    topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
  }
}

</script>
<style scoped>
@import "../assets/css/style-starter.css";

.tkk-box {
  /* width: 80%;
  height: 80%; */
  border: 10px dotted #ccc;
  margin: 30px 0;
}

.nav-top {
  margin: 20px;
  display: flex;
  justify-content: space-between;
}

.nav-top img {
  width: 150px;
  height: 150px;
}

.top-text {
  margin: 0 30px;
}

.nav-bottom {
  display: flex;
  justify-content: space-around;
}

.nav-bottom img {
  width: 200px;
  height: 400px;
}

.tkk-box button {
  width: 100px;
  text-align: center;
}
</style>
